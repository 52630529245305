
@page {
    padding-top:0.2em;
    padding-bottom:0.2em;
   /* Letter */
    size: us-letter;
    margin: 1in;
    }
   


/* .page {
    overflow: hidden;
    page-break-after: always;
} */



/* ul li{list-style-image: url('../images/dot.png') no-repeat left top;padding:0px 0px 0px;} */
.txt-rt {
    text-align: right !important
}

.wrap {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 0px 0px 0px;
    /*background:#a6a8ab;*/
    /* font-family: 'Open Sans', sans-serif !important; */
}

.wrap-mojo {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: 'proxima_novalight', Helvetica, sans-serif;
}

.wrap-kotak {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: 'proxima_novalight', Helvetica, sans-serif;
}

.wrap-gj {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: 'proxima_novalight', Helvetica, sans-serif;
}

.wrap-aditya {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: 'pf_encore_sans_probook', Helvetica, sans-serif;
}

.wrap-hdfc {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: "Roboto", arial, sans-serif;
}

.wrap-axis {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.wrap-icici {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: "Roboto", arial, sans-serif;
}

.wrap-bajaj {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: "Roboto", arial, sans-serif;
}

.wrap-ashika {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: "Roboto", arial, sans-serif;
}

.wrap-swastika {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto 0px;
    width: 1024px;
    padding: 0px 15px 0px 8px;
    background: #FFF;
    font-family: "Roboto", arial, sans-serif;
}

.wrap-new {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    text-align: center;
    margin: 0 auto 0px;
    width: 1024px;
    position: relative;
    z-index: 10;
    /* display: none; */
}

.coverbg {
    border-left: 40px solid #81acf7;
    border-right: 40px solid #81acf7;
    border-bottom: 40px solid #81acf7;
}

.maindiv-financial {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-quality {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-valuation {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-return {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-user_profile {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-executive {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-liquidity {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-mojo {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-risk {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-portfolio {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-valuation {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-diversification {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-holding {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindiv-ratio {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}



/* *************cio report 02052020************ */
.top-main_cover {
    width: 100%;
    float: left;
    margin: auto;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    background-color: #FFFFFF;
    border: 0px solid #a6a8ab;
    margin-bottom: 0px;
    height: auto;
    overflow: hidden;
    page-break-after: always !important;
    page-break-inside: avoid !important;
}

.top-main {
    width: 100%;
    float: left;
    margin: auto;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    background-color: #FFFFFF;
    border: 2px solid #231f20;
    margin-bottom: 0px;
    page-break-after: always !important;
    page-break-before: avoid !important;
    page-break-inside: avoid !important;
    height: auto;
}

.maindiv-profile {
    width: 100%;
    float: left;
    margin: auto;
    height: 1200px;
}

.maindivpage {
    width: 99.9%;
    float: left;
    margin: 0px 0%;
    height: auto;
}

.top-main_cover .maindivpage {
    width: 100%;
    float: left;
    margin: auto;
    height: auto;
}

.pagepadd {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 30px;
}

.pagepadd2 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 22px 0px;
}

.ulpadd {
    width: 100%;
    float: left;
    margin: auto;
    padding: 15px 0px;
}

.ulpadd ul {
    padding: 0px 0px 0px 16px;
    font-size: 15px;
}

.ulpadd ul li {
    list-style: disc;
    margin-bottom: 10px;
}

/* ***********18052020*************** */
.covergreen {
    border-left: 40px solid #3ab54a;
    border-right: 40px solid #3ab54a;
    border-bottom: 40px solid #3ab54a;
}

.covergreen-light {
    border-left: 40px solid #66c29d;
    border-right: 40px solid #66c29d;
    border-bottom: 40px solid #66c29d;
}

.coverorange {
    border-left: 40px solid #fbb03b;
    border-right: 40px solid #fbb03b;
    border-bottom: 40px solid #fbb03b;
}

.coverred {
    border-left: 40px solid #ff0000;
    border-right: 40px solid #ff0000;
    border-bottom: 40px solid #ff0000;
}

.coverred-light {
    border-left: 40px solid #fc7766;
    border-right: 40px solid #fc7766;
    border-bottom: 40px solid #fc7766;
}

.coverhead-green {
    color: #3ab54a !important;
}

.coverhead-green-light {
    color: #66c29d !important;
}

.coverhead-orange {
    color: #fbb03b !important;
}

.coverhead-red {
    color: #ff0000 !important;
}

.coverhead-red-light {
    color: #fc7766 !important;
}

.green {
    color: #3ab54a !important;
    background-color: transparent !important;
}

.green-light {
    color: #66c29d !important;
}

.orange {
    color: #fbb03b !important;
}

.red {
    color: #ff0000 !important;
    background-color: transparent !important;
}

.red-light {
    color: #fc7766 !important;
}

.blue {
    color: #ffffff !important;
    background-color: transparent !important;
}

.coverbutton-green {
    background-color: #3ab54a !important;
}

.coverbutton-green-light {
    background-color: #66c29d !important;
}

.coverbutton-orange {
    background-color: #fbb03b !important;
}

.coverbutton-red {
    background-color: #ff0000 !important;
}

.coverbutton-red-light {
    background-color: #fc7766 !important;
}

.greenbg {
    background-color: #37b34a !important;
}

.greenbg-light {
    background-color: #66c29d !important;
}

.orangebg {
    background-color: #fbb03b !important;
}

.redbg {
    background-color: #ff0000 !important;
}

.redbg-light {
    background-color: #fc7766 !important;
}

.yellowbg {
    background: #feeedb !important;
}

.greybg {
    background: #a6a8ab !important;
}

.greybg_1 {
    background: #808184 !important;
}

.greybg_2 {
    background: #e6e7e8 !important;
}

.blackbg {
    background: #000 !important;
}

.border-green_new {
    border: 2px solid #37b34a;
}

.border-green-light_new {
    border: 2px solid #66c29d;
}

.border-orange_new {
    border: 2px solid #fbb03b;
}

.border-red_new {
    border: 2px solid #ff0000;
}

.border-red-light_new {
    border: 2px solid #fc7766;
}

.border-grey_new {
    border: 2px solid #fc715e;
}

.border-green_new1 {
    border: 1px solid #37b34a;
}

.border-green-light_new1 {
    border: 1px solid #65c29c;
}

.border-orange_new1 {
    border: 1px solid #fbb03b;
}

.border-red_new1 {
    border: 1px solid #ff0000;
}

.border-red-light_new1 {
    border: 1px solid #fc7766;
}

.border-grey_new1 {
    border: 1px solid #a6a8ab;
}


.green-dot {
    background-color: #008d52 !important;
}

.green-light-dot {
    background-color: #65c29c !important;
}

.orange-dot {
    background-color: #fcc751 !important;
}

.red-dot {
    background-color: #ff0000 !important;
}

.red-light-dot {
    background-color: #fc715e !important;
}

.yellow-dot {
    background: #feeedb !important;
}

.grey-dot {
    background: #a6a8ab !important;
}

.stcover1 {
    width: 100%;
    float: left;
    margin: auto;
    border-left: 2.4px solid #FFF;
    background-color: #0046c1;
    height: auto;
    border-right: 2.4px solid #FFF;
    border-bottom: 2.4px solid #FFF;
}

.stcover2 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 64px 35px 25px 35px;
    text-align: center;
}

.stcover2 img {
    width: auto;
    height: 200px;
}

.stcover3 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 35px 0px;
    text-align: right;
    font-size: 20px;
    color: #FFF;
}

.stcover4 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 44px 35px 65px;
    vertical-align: top;
    height: auto;
}

.stcover5 {
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
}

.stcover6 {
    vertical-align: bottom;
    font-size: 40px;
    line-height: 46px;
    font-weight: 600;
}

.stcover7 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 35px 0px;
    position: relative;
}

.stcover8 {
    border-right: 1px solid #808184;
    height: 468px;
}

.stcover9 {
    width: 100%;
    float: left;
    padding-top: 0px;
    height: 130px;
}

.stcover10 {
    font-size: 36px;
    color: #FFF;
    line-height: 36px;
}

.stcover23 {
    font-size: 54px;
    color: #FFF;
    line-height: 58px;
    font-weight: 600;
    margin-bottom: 3px;
    font-family: 'DM Sans', sans-serif;
}

.stcover11 {
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 8px;
}

.stcover12 {
    width: 100%;
    float: left;
    height: 100px;
}

.stcover13 {
    height: auto;
}

.stcover14 {
    width: 100%;
    float: left;
    padding: 0px 38% 0px 15px;
}

.stcover15 {
    width: 100%;
    float: left;
    margin: 40px 18px 0px;
}

.stcover16 {
    width: 100%;
    float: left;
    padding: 54px 30px 0px 15px;
}

.stcover17 {
    font-size: 16px;
    color: #FFF;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 4px;
}

.stcover18 {
    font-size: 16px;
    color: #FFF;
    line-height: 20px;
}

.stcover19 {
    padding-right: 0px;
}

.stcover20 {
    width: 100%;
    float: left;
    padding: 0px 0px 0px;
    text-align: right;
}

.stcover21 {
    width: 100%;
    float: left;
    position: relative;
    bottom: 0;
    right: 0;
}

.stcover22 {
    position: absolute;
    top: 8px;
    right: 20px;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    display: block;
}

.stcover24 {
    padding-top: 18px;
}

.stcover9_9 {
    width: 100%;
    float: left;
    padding-top: 0px;
    height: 166px;
}

.stcover12_12 {
    width: 100%;
    float: left;
    height: 128px;
}

.mojoreportlogo {
    padding-right: 0;
    text-align: left;
    float: left;
    position: relative;
    right: 20px;
    top: 14px;
}

.mojoreportlogo img {
    height: 18px;
}

.mojoreportlogo2 {
    display: inline-block;
    padding-right: 0px;
    position: relative;
    top: 15px;
}

.mojoreportlogo2 img {
    height: 25px;
    display: none;
}

.streportmain3_2 {
    width: auto;
    font-size: 18px;
    line-height: 23px;
    padding: 3px 7px;
    color: #FFF;
    font-weight: 700;
    background-color: #231f20;
}

.pgraph {
    margin-bottom: 6px;
}

.pgraph img {
    height: 220px;
    width: 100%;
}

.bottomhead {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 38px;
}

.paddbottom {
    width: 100%;
    float: left;
    margin: auto;
    padding: 12px 0px;
}

.bottomhead1 {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
}

.bottomhead2 {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 4px;
}

.bottomhead3 {
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    font-weight: 600;
}

.bottomtext1 {
    width: 100%;
    float: left;
    margin:auto;
}

.bottomtext2 {
    width: 50%;
    float: left;
    margin:auto;
}

#stockscore {
    min-width: 100%;
    max-width: 100%;
    float: left;
    margin-left: 0%;
    background: #FFFFFF;
    padding: 0px 0px 6px;
    margin-bottom: 0px;
    margin-top: 0%;
}

#stockscore table {
    width: 100%;
}

#stockscore .highcharts-container {
    height: 286px !important;
    width: 100% !important;
}

#stockscore .highcharts-container .highcharts-root {
    height: 286px !important;
    width: 100% !important;
}


.score1 {
    width: 20% !important;
}

.text-right {
    text-align: right !important;
}

.scorered {
    width: 100%;
    float: left;
    height: 14px;
    position: relative;
    background: #ec1c24;
    color: #FFFFFF;
    padding: 3px 3px;
    text-align: center;
    font-size: 16px;
    height: 16px;
}

.scoregreen {
    width: 100%;
    float: left;
    height: 14px;
    position: relative;
    background: #37b34a;
    color: #FFFFFF;
    padding: 3px 3px;
    text-align: center;
    font-size: 16px;
    height: 16px;
}

.scoregrey {
    width: 100%;
    float: left;
    height: 14px;
    position: relative;
    background: #d0d2d3;
    color: #FFFFFF;
    padding: 3px 3px;
    text-align: center;
    font-size: 16px;
    height: 16px;
}

.score1 .sellbg {
    width: 100%;
    float: left;
    position: relative;
    background: #fc7765;
    color: #FFFFFF;
    padding: 3px 3px;
    text-align: center;
    font-size: 16px;
    height: 18px;
}

.score1 .greybg {
    width: 100%;
    float: left;
    position: relative;
    background: #ffbb02;
    color: #FFFFFF;
    padding: 3px 3px;
    text-align: center;
    font-size: 16px;
    height: 18px;
}

.score1 .list11 {
    position: relative;
    left: 0;
    width: 300px;
    padding-right: 0px;
    text-align: right;
    top: 2px;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

.score1 .arrowhead {
    font-size: 16px;
    font-weight: 900;
    color: #000;
    z-index: 1;
    position: absolute;
    margin-left: -42px;
    top: -52px;
    line-height: 18px;
    width: 86px;
}


.streporthead4 {
    width: 100%;
    float: left;
    margin: auto;
    margin-top: 0px;
    background-color: #FFFFFF;
    padding: 0px 0px;
    color: #FFFFFF;
    position: relative;
    border: 0px solid #686566;
}

.sthead3 {
    width: 92%;
    float: left;
    margin: auto;
    border-bottom: 5px solid #231f20;
    margin: 0px 4% 0px;
    padding: 38px 0px 0px;
}

.sthead4 {
    width: auto;
    float: left;
    font-size: 28px;
    line-height: 28px;
    text-align: left;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    padding: 0px 0px 0px;
    color: #231f20;
}

.sthead5 {
    width: auto;
    float: right;
    text-align: right;
    color: #333;
    font-size: 16px;
    line-height: 18px;
    padding: 6px 0px 0px;
    position: relative;
    top: -6px;
}

.top-main_cover {
    width: 100%;
    float: left;
    margin: auto;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    background-color: #FFFFFF;
    border: 0px solid #a6a8ab;
    margin-bottom: 0px;
}

.top-main {
    width: 100%;
    float: left;
    margin: auto;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    background-color: #FFFFFF;
    border: 2px solid #231f20;
    margin-bottom: 0px;
}

.top-main01 {
    width: 100%;
    height: 1450px;
    float: left;
    margin: auto;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 0px;
    background-color: #d0d2d3;
}

.border-red {
    border-bottom: 1px solid #f36f72;
}

.border-green {
    border-bottom: 1px solid #00b050;
}

.border-orange {
    border-bottom: 1px solid #FFA500;
}

.border- {
    border-bottom: 1px solid #FFA500;
}

.border-yellow {
    border-bottom: 1px solid #FFA500;
}

.border-blue {
    border-bottom: 1px solid #1b3b71;
}

.textmargin {
    padding: 32px 15px;
}

.stfooter_new {
    width: 92%;
    float: left;
    margin: 0px 4% 0px;
    border-top: 5px solid #231f20;
    height: 105px !important;
    border-bottom: 0px solid #ddd;
}

.stfooter_new2 {
    width: 50%;
    float: left;
    margin: auto;
    padding: 0px 0px 0px;
    color: #231f20;
    font-size: 18px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    text-align: left;
    text-transform: uppercase;
}

.stfooter_new1 {
    width: 33%;
    float: left;
    margin: auto;
    padding: 12px 0px 0px;
    color: #231f20;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
}

.stfooter_new3 {
    width: 50%;
    float: left;
    margin: auto;
    padding: 3px 0px 0px;
    color: #231f20;
    text-align: right;
}

.stfooter_new4 {
    display: inline-block;
    padding: 0px 20px 0px 0px;
    border-right: 0px solid #000;
    margin-right: 0px;
}

.stfooter_new4 img {
    height: auto;
}

.footerdate {
    padding: 4px 24px 0px 0px;
    position: relative;
    top: 0px;
}

.footernumber {
    padding: 6px 10px;
    background-color: #000;
    color: #FFFFFF;
}

.pagecontent {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 40px;
}

.pagecontent2 {
    width: 100%;
    /*! float:left; */
    margin: auto;
    padding: 30px 0px 12px;
    margin-top: 10px;
    border-bottom: 2px solid #231f20;
}

.pageheading {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    font-weight: 700;
    padding: 40px 0px;
    text-transform: uppercase;
}

.pageheading2 {
    width: 100%;
    float: left;
    margin: 0px 0px;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    padding: 10px 48px;
    color: #FFFFFF;
    background-color: #37b34a;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 10px;
}

.graphcontainer {
    width: 100%;
    float: left;
    margin: 0px 0px;
    padding: 20px 0px;
    color: #FFFFFF;
}

.graphcontainer .highcharts-container {
    width: 100% !important;
    height: 224px !important;
}

.graphcontainer .highcharts-container .highcharts-root {
    width: 100% !important;
    height: 224px !important;
}

.graphcontainer .highcharts-container .highcharts-background {
    width: 100% !important;
    height: 224px !important;
}

#performanceanalysis {
    width: 100% !important;
    float: left;
}

#performanceanalysis .highcharts-container .highcharts-background {
    width: 100% !important;
    height: 200px !important;
}

#performanceanalysis .highcharts-container {
    width: 100% !important;
    height: 270px !important;
}

#performanceanalysis .highcharts-container .highcharts-root {
    width: 100% !important;
    height: 270px !important;
}

#acceptancecontainer {
    height: 295px !important;
    width: 100% !important;
    padding: 10px 0px;
    background-color: #e2e2e2;
}

#acceptancecontainer .highcharts-container {
    height: 295px !important;
    width: 100% !important;
}

#acceptancecontainer .highcharts-container .highcharts-root {
    height: 295px !important;
    width: 100% !important;
}

#acceptancecontainer .highcharts-container .highcharts-root .highcharts-background {
    height: 295px !important;
    width: 100% !important;
    padding: 10px 0px 0px;
}

#allocationpie {
    width: 100% !important;
    float: left;
}

#allocationpie .highcharts-container .highcharts-background {
    width: 100% !important;
    height: 190px !important;
}

#allocationpie .highcharts-container {
    width: 100% !important;
    height: 190px !important;
}

#allocationpie .highcharts-container .highcharts-root {
    width: 100% !important;
    height: 190px !important;
}

#allocationpie .highcharts-container .highcharts-background {
    width: 100% !important;
    height: 190px !important;
}

#allocationbar {
    width: 100% !important;
    float: left;
    padding: 0px 0px 16px;
}

#allocationbar .highcharts-container .highcharts-background {
    width: 100% !important;
    height: 190px !important;
}

#allocationbar .highcharts-container {
    width: 100% !important;
    height: 190px !important;
}

#allocationbar .highcharts-container .highcharts-root {
    width: 100% !important;
    height: 190px !important;
}

#allocationbar .highcharts-container .highcharts-background {
    width: 100% !important;
    height: 190px !important;
}

#allocationbar span {
    font-style: italic;
}

.captextnew1 {
    font-style: italic;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 10px;
    text-align: center;
}

.graphcontent {
    width: 100%;
    float: left;
    margin: 0px 0%;
    padding: 20px 0px 0px 35px;
    font-size: 16px;
    line-height: 20px;
}

.mt1 {
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    padding: 15px 0px 0px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}

.mt2 {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 5px 2px 10px;
}

.mt3 {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 5px 2px 0px;
}

.borderright2 {
    border-right: 2px solid #231f20;
    min-height: 315px;
    margin-bottom: 15px;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

.sttable14 table td.green,
.table1 td.green,
.table1 div.green,
.table-card td.green,
.table-card div.green {
    background-color: #cceddc !important;
}

.sttable14 table td.red,
.table1 td.red,
.table1 div.red,
.table-card td.red,
.table-card div.red {
    background-color: #f2d2d5 !important;
}

.sttable14 table td.grey,
.table1 td.grey,
.table1 div.grey,
.table-card td.grey,
.table-card div.grey {
    background-color: transparent !important;
}

.mt4 {
    width: 52%;
}

.sttable14 {
    width: 100%;
    float: left;
    margin: auto;
}

/* .sttable14 .table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 2px 12px;
    border: 1px solid #a3a3a7;
    font-size: 16px;
} */

.sttable14 .table {
    margin-bottom: 4px;
}

.borderleft {
    border-left: 0px solid #000 !important;
}

.borderright {
    border-right: 0px solid #000 !important;
}

.white {
    color: #FFFFFF !important;
}

.pagecontent table.table {
    margin-top: 0;
    margin-bottom: 0;
}

/* .mt5 .table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 3px 5px;
    border: 0px solid #a3a3a7;
    font-size: 15px;
} */

.mt6 {
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    padding: 38px 0px 0px;
}

.mt7 {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 0px 0px 0px;
    margin-bottom: 0;
    font-weight: 700;
}

.pagecontent3 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 0px 5px;
    border-bottom: 2px solid #231f20;
    margin-top: 0px;
}

.border_not_bottom {
    border-bottom: 0px solid #231f20 !important;
}

.border_not2 {
    border-bottom: 0px solid #231f20 !important;
    border-top: 1px solid #231f20 !important;
}

.border_bottom {
    border-bottom: 1px solid #231f20 !important;
}

.border_top {
    border-top: 1px solid #231f20 !important;
}

.almas_pdf .mt92 {
    padding-right: 6px !important;
    color: #4caf50;
    font-size: 16px;
    font-weight: 700;
}

.almas_pdf .mt93 {
    padding-left: 6px !important;
    color: #4caf50;
    font-size: 16px;
    font-weight: 700;
}

.almas_pdf .mt9 {
    color: #4caf50;
    font-size: 16px;
    font-weight: 700;
}

.mt8 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    padding: 0px 2px 10px;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
}

.mt9 {
    color: #582FDA;
    font-size: 16px;
    font-weight: 700;
}

.mt92 {
    padding-right: 6px !important;
    color: #582FDA;
    font-size: 16px;
    font-weight: 700;
}

.mt93 {
    padding-left: 6px !important;
    color: #582FDA;
    font-size: 16px;
    font-weight: 700;
}

.mt11 {
    background-color: #582FDA;
    padding: 10px;
    width: 100%;
    height: 312px;
    margin: 32px 0px 0px;
}

.mt12_1 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
    padding: 28px 0px 0px;
}

.mt12_2 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
}

.mt12_3 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.mt12_4 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.mt12_5 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.mt12_6 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.mt12_7 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
}

.mt12_8 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.mt12_9 {
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    text-align: center;
}

/* 
.sttable14_1 {
    width: 100%;
    float: left;
    margin: auto;
}

.sttable14_1 .table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 2px 5px;
    border: 1px solid #a3a3a7;
    font-size: 16px;
}

.sttable14_1 strong {
    font-weight: 600;
}

.sttable14_2 {
    width: 100%;
    float: left;
    margin: auto;
}

.sttable14_2 .table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 1.8px 5px;
    border: 0px solid #a3a3a7;
    border-bottom: 1px solid #a3a3a7;
    font-size: 16px;
} */

.mt10 {
    width: 25%;
}

.mt10_1 {
    width: 20%;
}

.mt10_3 {
    width: 8%;
}

.mt10_2 {
    width: 22%;
}

.mt10_10 {
    width: 30%;
}

.mt10_1_1 {
    width: 25%;
}

.mt13 {
    font-size: 36px;
    line-height: 38px;
    text-align: center;
    font-weight: 700;
    padding: 40px 0px 0px;
}

.mt14 {
    font-size: 22px;
    line-height: 24px;
    text-align: left;
    padding: 40px 0px 0px;
    color: #37b34a;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
}

.mt15 {
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    padding: 8px 30px;
    color: #FFFFFF;
    background-color: #929497;
    margin-top: 30px;
}

.mt16 {
    width: 100%;
    float: left;
    margin: auro;
    padding: 10px 0px 0px;
}

.mt16 ul {
    padding: 0px 0px 10px;
}

/* .mt16 ul{padding-left:20px;} */
/* .mt16 ul li{list-style:disc;} */
.mt17 {
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    padding: 0px 0px 0px;
    font-weight: 700;
    margin-bottom: 4px;
}

.mt18 {
    width: 100%;
    left: 0;
    padding: 0px 0px 0px;
    margin-bottom: 4px;
    position: relative;
}

.mt18_1 {
    width: 100%;
    font-size: 16px;
    position: relative;
    line-height: 18px;
    top: 440px;
    text-align: center;
    padding: 0px 0px 0px;
    color: #00add4;
}

.mt19 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    padding: 20px 0px 0px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}

.pagecontent4 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 10px 0px 0px;
    border-bottom: 0px solid #231f20;
    margin-top: 0px;
}

.pagecontent5 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 10px 0px 0px;
    border-bottom: 0px solid #231f20;
    margin-top: 0px;
}

.bordertop {
    border-top: 1px solid #a3a3a7 !important;
}

.pagecontent6 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 40px;
    background-color: #00add4;
    min-height: 1236px;
    max-height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.mt22 {
    font-size: 36px;
    line-height: 38px;
    text-align: center;
    padding: 50% 0px 0px;
    font-weight: 700;
    color: #FFFFFF;
}

.mt25 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 12px 130px;
    margin: 0px 0px 0px;
}

.mt25 .pagecontent2 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 30px 0px 12px;
    border-top: 0px solid #231f20;
    margin-top: 10px;
    border-bottom: 0px solid #231f20;
}

.mt21 {
    width: 100%;
    float: left;
    margin: auto;
    background-color: #00add4;
    height: 60px;
    padding: 12px 0px;
    text-align: center;
    margin: 40px 0px 0px;
    font-family: 'DM Sans', sans-serif;
}

.mt23 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    padding: 0px 0px 0px;
    font-weight: 700;
    color: #FFFFFF;
}

.mt24 {
    font-size: 36px;
    line-height: 38px;
    text-align: center;
    padding: 0px 0px 0px;
    font-weight: 700;
    color: #FFFFFF;
}

.mt26 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 0px;
    margin: 0px 0px 0px;
}

.mt27 {
    width: 100%;
    float: left;
    margin: auto;
    padding: 30px 0px 0px;
}

.mt28 {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
}

/* **************end*************** */

.margin05 {
    margin-top: 5px;
}

.margin10 {
    margin-top: 10px;
}

.margin15 {
    margin-top: 15px;
}

.margin20 {
    margin-top: 20px;
}

.margin25 {
    margin-top: 25px;
}

.margin30 {
    margin-top: 30px;
}

.margin35 {
    margin-top: 35px;
}

.margin40 {
    margin-top: 10px;
}

.margin45 {
    margin-top: 45px;
}

.margin50 {
    margin-top: 50px;
}

.margin60 {
    margin-top: 60px;
}

.margin70 {
    margin-top: 70px;
}

.margin80 {
    margin-top: 80px;
}

.margin90 {
    margin-top: 90px;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

/* //////text color///////////////// */
.green-text {
    color: #00b050 !important;
}

.red-text {
    color: #ff0000 !important;
}

.grey-text {
    color: #d9d9d9 !important;
}

.yellow-text {
    color: #FFA500 !important;
}

.orange-text {
    color: #FFA500 !important;
}

.yellow-text2 {
    font-size: 15px;
    color: #ffc000 !important;
}

/* //////text heading color///////////////// */
.green-text-head {
    font-size: 15px;
    color: #00b050 !important;
    font-weight: 600;
}

.red-text-head {
    font-size: 15px;
    color: #ff0000 !important;
    font-weight: 600;
}

.yellow-text-head {
    font-size: 15px;
    color: #ffc000 !important;
    font-weight: 600;
}

.grey-text-head {
    font-size: 15px;
    color: #d9d9d9 !important;
    font-weight: 600;
}

.orange-text-head {
    font-size: 15px;
    color: #FFA500 !important;
    font-weight: 600;
}

.yellow-text-head {
    font-size: 15px;
    color: #FFA500 !important;
    font-weight: 600;
}

.yellow-text2-head {
    font-size: 15px;
    color: #ffc000 !important;
    font-weight: 600;
}

/* **************************************** */
/* 
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border: 1px solid #e6e4e4;
    font-size: 13px;
} */

.trbg {
    background-color: transparent;
}

.trbg2 {
    background-color: #d1d2d4;
}

/* 
.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: transparent;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 3px;
} */

.green-bg {
    background-color: #c5e1d1 !important;
}

.red-bg {
    background-color: #f9babc !important;
}

.yellow-bg {
    background-color: #ffd966 !important;
}

.grey-bg {
    background-color: #d0d1d2 !important;
}


.tab-bg01 {
    background-color: #a9d08e;
}

.tab-bg02 {
    background-color: #ffd966;
}

.tab-bg03 {
    background-color: #ffc7ce;
}

.bg-green {
    background-color: #a9d08e !important;
}

.bg-red {
    background-color: #f4b084 !important;
}

.bg-yellow {
    background-color: #ffd966 !important;
}

.bg-green02 {
    background-color: #c6efce;
}

.bg-red02 {
    background-color: #ffc7ce;
}

.tab-bg-green {
    background-color: #00b050;
}

.tab-bg-red {
    background-color: #c00000;
}

.tab-bg-yellow {
    background-color: #ffc000;
}

.tab-bg-below {
    background-color: #00008b;
}

.green-bg0 {
    background-color: #a9d08e;
}

.red-bg0 {
    background-color: #f4b084;
}

.yellow-bg0 {
    background-color: #ffd966;
}

.grey-bg0 {
    background-color: #00b050;
}

.green-text {
    color: #00b050;
}

.red-text {
    color: #ff0000;
}

.yellow-text {
    color: #00b050;
}

.grey-text {
    color: #00b050;
}

.graphcontainer img {
    height: 224px;
    width: auto;
}

/* GJ************* */
.gj_pdf .blue {
    color: #d7bf65 !important;
}

.gj_pdf .coverbg {
    border-left: 40px solid #007672;
    border-right: 40px solid #007672;
    border-bottom: 40px solid #007672;
}

.gj_pdf .stcover11 {
    font-size: 24px;
    line-height: 24px;
    color: #d7bf65;
    margin-bottom: 8px;
}

.gj_pdf .stcover2 {
    text-align: center;
}

.gj_pdf .stcover2 img {
    width: auto;
    height: 240px;
}

.gj_pdf .stcover13 {
    height: 576px;
}

.gj_pdf .stcover22 {
    display: none;
}

.gj_pdf .mojoreportlogo {
    padding-right: 0;
    text-align: left;
    float: left;
    position: relative;
    right: 20px;
    top: 10px;
}

.gj_pdf .mojoreportlogo img {
    height: 46px;
}

.gj_pdf .mojoreportlogo2 {
    display: inline-block;
    padding-right: 0px;
    position: relative;
    top: 0px;
}

.gj_pdf .mojoreportlogo2 img {
    height: 54px;
}

.axis_pdf .stfooter_new4 {
    display: inline-block;
    padding: 0px 6px 0px 0px;
    border-right: 1px solid #000;
    margin-right: 8px;
}

.gj_pdf .stfooter_new5 {
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.gj_pdf .stfooter_new5 img {
    height: 26px;
}

.gj_pdf .stfooter_new4 img {
    height: 30px;
}


/* mosl************* */
.mosl_pdf .blue {
    color: #fbb12f !important;
}

.mosl_pdf .coverbg {
    border-left: 40px solid #fbb12f;
    border-right: 40px solid #fbb12f;
    border-bottom: 40px solid #fbb12f;
}

.mosl_pdf .stcover11 {
    font-size: 24px;
    line-height: 24px;
    color: #fbb12f;
    margin-bottom: 8px;
}

.mosl_pdf .stcover2 {
    text-align: center;
}

.mosl_pdf .stcover2 img {
    width: auto;
    height: 240px;
}

.mosl_pdf .stcover13 {
    height: 576px;
}

.mosl_pdf .stcover22 {
    display: none;
}

.mosl_pdf .mojoreportlogo {
    padding-right: 0;
    text-align: left;
    float: left;
    position: relative;
    right: 20px;
    top: 10px;
}

.mosl_pdf .mojoreportlogo img {
    height: 45px;
}

.mosl_pdf .mojoreportlogo2 {
    display: inline-block;
    padding-right: 0px;
    position: relative;
    top: 0px;
}

.mosl_pdf .mojoreportlogo2 img {
    height: 54px;
}

.axis_pdf .stfooter_new4 {
    display: inline-block;
    padding: 0px 6px 0px 0px;
    border-right: 1px solid #000;
    margin-right: 8px;
}

.mosl_pdf .stfooter_new5 {
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.mosl_pdf .stfooter_new5 img {
    height: 26px;
}

.mosl_pdf .stfooter_new4 img {
    height: 30px;
}

/* axis************* */
.axis_pdf .blue {
    color: #d4a0b7 !important;
}

.axis_pdf .coverbg {
    border-left: 40px solid #96144c;
    border-right: 40px solid #96144c;
    border-bottom: 40px solid #96144c;
}

.axis_pdf .stcover11 {
    font-size: 24px;
    line-height: 24px;
    color: #d4a0b7;
    margin-bottom: 8px;
}

.axis_pdf .stcover2 {
    text-align: center;
}

.axis_pdf .stcover2 img {
    width: auto;
    height: 240px;
}

.axis_pdf .stcover13 {
    height: 576px;
}

.axis_pdf .stcover22 {
    display: none;
}

.axis_pdf .mojoreportlogo {
    padding-right: 0;
    text-align: left;
    float: left;
    position: relative;
    right: 20px;
    top: 10px;
}

.axis_pdf .mojoreportlogo img {
    height: 45px;
}

.axis_pdf .mojoreportlogo2 {
    display: inline-block;
    padding-right: 0px;
    position: relative;
    top: 0px;
}

.axis_pdf .mojoreportlogo2 img {
    height: 54px;
}

.axis_pdf .stfooter_new4 {
    display: inline-block;
    padding: 0px 6px 0px 0px;
    border-right: 1px solid #000;
    margin-right: 8px;
}

.axis_pdf .stfooter_new5 {
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.axis_pdf .stfooter_new5 img {
    height: 26px;
}

.axis_pdf .stfooter_new4 img {
    height: 30px;
}

/* aditya************* */
.aditya_pdf .stcover1 {
    width: 100%;
    float: left;
    margin: auto;
    border: 2.4px solid #FFF;
    background-color: #cc1e35;
    height: 1408px;
}

.aditya_pdf .stcover8 {
    border-right: 1px solid #FFFFFF;
    height: 468px;
}

.aditya_pdf .blue {
    color: #FFFFFF !important;
}

.aditya_pdf .coverbg {
    border-left: 40px solid #8c9c71;
    border-right: 40px solid #8c9c71;
    border-bottom: 40px solid #8c9c71;
}

.aditya_pdf .stcover11 {
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.aditya_pdf .stcover2 {
    text-align: center;
}

.aditya_pdf .stcover2 img {
    width: auto;
    height: 240px;
}

.aditya_pdf .stcover13 {
    height: 571px;
}

.aditya_pdf .stcover22 {
    display: none;
}

.aditya_pdf .mojoreportlogo {
    padding-right: 0;
    text-align: left;
    float: left;
    position: relative;
    right: 18px;
    top: 24px;
}

.aditya_pdf .mojoreportlogo img {
    height: 40px;
}

.aditya_pdf .mojoreportlogo2 {
    display: inline-block;
    padding-right: 0px;
    position: relative;
    top: 0px;
}

.aditya_pdf .mojoreportlogo2 img {
    height: 60px;
}

.aditya_pdf .stfooter_new4 {
    display: inline-block;
    padding: 0px 6px 0px 0px;
    border-right: 1px solid #000;
    margin-right: 8px;
}

.aditya_pdf .stfooter_new5 {
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.aditya_pdf .stfooter_new5 img {
    height: 26px;
}

.aditya_pdf .stfooter_new4 img {
    height: 30px;
}

/* al-mas************* */
.almas_pdf .stcover1 {
    width: 100%;
    float: left;
    margin: auto;
    border: 2.4px solid #FFF;
    background-color: #000;
    height: 1408px;
}

.almas_pdf .stcover8 {
    border-right: 1px solid #FFFFFF;
    height: 468px;
}

.almas_pdf .blue {
    color: #FFFFFF !important;
}

.almas_pdf .coverbg {
    border-left: 40px solid #4caf50;
    border-right: 40px solid #4caf50;
    border-bottom: 40px solid #4caf50;
}

.almas_pdf .stcover11 {
    font-size: 24px;
    line-height: 24px;
    color: #4caf50;
    margin-bottom: 8px;
}

.almas_pdf .stcover2 {
    text-align: center;
}

.almas_pdf .stcover2 img {
    width: auto;
    height: 240px;
}

.almas_pdf .stcover13 {
    height: 571px;
}

.almas_pdf .stcover22 {
    display: none;
}

.almas_pdf .mojoreportlogo {
    padding-right: 0;
    text-align: left;
    float: left;
    position: relative;
    right: 18px;
    top: 24px;
}

.almas_pdf .mojoreportlogo img {
    height: 40px;
}

.almas_pdf .mojoreportlogo2 {
    display: inline-block;
    padding-right: 0px;
    position: relative;
    top: 8px;
}

.almas_pdf .mojoreportlogo2 img {
    height: 60px;
}

.almas_pdf .stfooter_new4 {
    display: inline-block;
    padding: 0px 6px 0px 0px;
    border-right: 0px solid #000;
    margin-right: 8px;
}

.almas_pdf .stfooter_new5 {
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.almas_pdf .stfooter_new5 img {
    height: 36px;
}

.almas_pdf .stfooter_new4 img {
    height: 30px;
}

@media only screen and (max-width:960px) {
    .wrap {
        width: 100%;
        padding: 0px 2px 0px 2px;
    }

    .maindivpage {
        min-height: 536px;
        height: auto;
    }

    .stcover1 {
        height: auto;
        border: 1px solid #FFF;
    }

    .coverbg {
        border-left: 8px solid #00add4;
        border-right: 8px solid #00add4;
        border-bottom: 8px solid #00add4;
    }

    .stcover2 {
        padding: 12px 20px 6px 20px;
    }

    .stcover3 {
        padding: 0px 10px 0px;
        text-align: center;
    }

    .stcover4 {
        padding: 10px 6px 15px;
        vertical-align: top;
        height: auto;
    }

    .stcover6 {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }

    .stcover8 {
        border-right: 0px solid #FFF;
        height: auto;
    }

    .stcover14 {
        padding: 0px 0% 0px 0px;
    }

    .stcover7 {
        padding: 0px 10px 0px;
    }

    .stcover10 {
        font-size: 16px;
        line-height: 18px;
    }

    .stcover12 {
        text-align: center;
    }

    .stcover12 {
        width: 100%;
        float: left;
        height: auto;
    }

    .stcover23 {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
    }

    .stcover20 {
        width: 100%;
        float: left;
        padding: 6px 0px 15px;
        text-align: left;
    }

    .stcover13 {
        height: auto;
    }

    .mojoreportlogo {
        display: inline-block;
        padding-right: 0px;
        top: 10px;
        position: relative;
        right: 0px;
    }

    .mojoreportlogo2 {
        display: inline-block;
        padding-right: 15px;
        float: right;
    }

    .mojoreportlogo2 img {
        height: 14px;
        text-align: right;
        float: right;
    }

    .mojoreportlogo img {
        height: 12px;
    }

    .stcover22 {
        position: absolute;
        top: 5px;
        right: 14px;
        font-family: 'DM Sans', sans-serif;
        font-size: 12px;
    }

    .streportmain3_2 {
        width: auto;
        font-size: 16px;
        line-height: 18px;
        padding: 3px 7px;
        color: #FFF;
        font-weight: 700;
        background-color: #231f20;
        position: relative;
        top: 32px;
        float: left;
    }

    .sthead3 {
        width: 96%;
        float: left;
        margin: auto;
        border-bottom: 5px solid #231f20;
        margin: 0px 2% 0px;
        padding: 0px 0px 12px;
    }

    .sthead5 {
        width: auto;
        float: right;
        text-align: right;
        color: #333;
        font-size: 12px;
        line-height: 16px;
        padding: 10px 0px 0px;
        position: relative;
        top: 10px;
        left: 0;
    }

    .pagecontent {
        width: 100%;
        float: left;
        margin: auto;
        padding: 0px 6px;
    }

    .pageheading {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        font-weight: 700;
        padding: 10px 0px;
    }

    .pageheading2 {
        font-size: 18px;
        line-height: 20px;
        padding: 5px 6px;
    }

    .graphcontainer img {
        height: auto;
        width: 100%;
    }

    .graphcontent {
        margin: 0px 0%;
        padding: 4px 0px 0px 0px;
        font-size: 16px;
        line-height: 18px;
    }

    .mt1 {
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        padding: 0px 0px 0px;
        float: left;
        width: 100%;
    }

    .pagecontent2 {
        width: 100%;
        float: left;
        margin: auto;
        padding: 15px 0px 12px;
        border-top: 2px solid #231f20;
        margin-top: 0px;
        border-bottom: 0px solid #231f20;
    }

    .stfooter_new2 {
        width: 100%;
        float: left;
        margin: auto;
        padding: 6px 0px 0px;
        color: #231f20;
        font-size: 14px;
        font-weight: 600;
        font-family: 'DM Sans', sans-serif;
        text-align: left;
        text-transform: uppercase;
    }

    .stfooter_new3 {
        width: 100%;
        float: left;
        margin: auto;
        padding: 3px 0px 0px;
        color: #231f20;
        text-align: right;
    }

    .footerdate {
        padding: 9px 0px 0px 0px;
        position: relative;
        top: 0px;
        font-size: 11px;
        text-align: left;
        float: left;
    }

    .borderright2 {
        border-right: 0px solid #231f20;
        min-height: auto;
    }

    .mt15 {
        float: left;
        width: 100%;
        margin-top: 7px;
    }

    .mt13 {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-weight: 700;
        padding: 15px 0px 0px;
    }

    .mt25 {
        width: 100%;
        float: left;
        margin: auto;
        padding: 12px 10px;
        margin: 0px 0px 0px;
    }

    .mt21 {
        width: 100%;
        float: left;
        margin: auto;
        height: 60px;
        padding: 6px 0px;
        text-align: center;
        margin: 0px 0px 0px;
    }

    .mt23 {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        padding: 0px 0px 0px;
    }

    .mt24 {
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        padding: 0px 0px 0px;
    }

    .mt21 {
        height: auto;
        padding: 6px 0px;
        text-align: center;
        margin: 0px 0px 0px;
    }

    .footernumber {
        padding: 1px 4px;
        position: relative;
        top: 6px;
    }

    .pagecontent6 {
        max-height: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        min-height: 300px;
    }

    .mt22 {
        font-size: 20px;
        line-height: 26px;
        padding: 20px 0px 20px;
    }

    .mt8 {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        padding: 0px 2px 0px;
    }
/* 
    .sttable14_2 .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
        padding: 2px 0px;
        border: 0px solid #a3a3a7;
        border-bottom: 1px solid #a3a3a7;
        font-size: 11px;
    }

    .sttable14_1 .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
        padding: 2px 0px;
        border: 1px solid #a3a3a7;
        font-size: 11px;
    } */

    .margin40 {
        margin-top: 10px;
    }

    /* .table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
    } */

    .mt19 {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        padding: 6px 0px 0px;
    }

    .mt18_1 {
        width: 100%;
        font-size: 14px;
        position: relative;
        line-height: 18px;
        bottom: 0;
        text-align: center;
        padding: 0px 0px 5px;
        color: #00add4;
        top: 0px;
    }

    .mt14 {
        font-size: 18px;
        line-height: 20px;
        text-align: left;
        padding: 12px 0px 0px;
        color: #37b34a;
    }

    .mt12_1 {
        font-size: 16px;
        line-height: 16px;
        color: #FFFFFF;
        font-family: 'DM Sans', sans-serif;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0px;
        padding: 16px 0px 0px;
    }

    .mt6 {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        padding: 0px 0px 0px;
    }

    .stcover11 {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
    }

    .gj_pdf .stcover11 {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
    }

    .mosl_pdf .stcover11 {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
    }

    .axis_pdf .stcover11 {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
    }

    .aditya_pdf .stcover11 {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
    }

    .padd12 {
        float: left;
        margin-top: 10px;
    }

    .mojoreportlogo2 img {
        height: auto;
    }

    .stcover3 {
        padding: 0px 35px 0px;
        text-align: right;
        font-size: 12px;
        color: #FFF;
    }

    #stockscore .highcharts-container {
        height: 186px !important;
        width: 100% !important;
    }

    .stfooter_new4 img {
        height: 14px;
    }

    .mt11 {
        padding: 10px;
        width: 100%;
        height: auto;
        margin: 32px 0px 10px;
    }

    #allocationbar {
        padding: 0px 0px 0px;
    }

    .stfooter_new {
        width: 96%;
        float: left;
        margin: 8px 2% 8px;
        border-top: 2px solid #231f20;
        height: auto;
        border-bottom: 0px solid #ddd;
    }

    .mt9 {
        font-size: 12px;
    }
/* 
    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
        padding: 2px;
        font-size: 12px;
    } */

    .stcover2 img {
        width: 100%;
        height: auto;
    }

    /* ***********gj********** */
    .gj_pdf .stcover2 img {
        width: 100%;
        height: auto;
    }

    .gj_pdf .mojoreportlogo {
        display: inline-block;
        padding-right: 0px;
        top: 10px;
        position: relative;
        right: 0px;
    }

    .gj_pdf .mojoreportlogo2 {
        display: inline-block;
        padding-right: 15px;
        float: right;
        top: 8px;
    }

    .gj_pdf .mojoreportlogo2 img {
        height: 22px;
        text-align: right;
        float: right;
    }

    .gj_pdf .mojoreportlogo img {
        height: 22px;
    }

    .gj_pdf .stcover13 {
        height: auto;
    }

    .gj_pdf .stfooter_new2 img {
        height: 22px;
    }

    .gj_pdf .stfooter_new4 {
        display: inline-block;
        padding: 0px 2px 0px 0px;
    }

    .gj_pdf .stfooter_new5 img {
        height: 22px;
    }

    .gj_pdf .coverbg {
        border-left: 8px solid #007672;
        border-right: 8px solid #007672;
        border-bottom: 8px solid #007672;
    }

    /* ***********mosl********** */
    .mosl_pdf .stcover2 img {
        width: 100%;
        height: auto;
    }

    .mosl_pdf .mojoreportlogo {
        display: inline-block;
        padding-right: 0px;
        top: 10px;
        position: relative;
        right: 0px;
    }

    .mosl_pdf .mojoreportlogo2 {
        display: inline-block;
        padding-right: 15px;
        float: right;
        top: 8px;
    }

    .mosl_pdf .mojoreportlogo2 img {
        height: 22px;
        text-align: right;
        float: right;
    }

    .mosl_pdf .mojoreportlogo img {
        height: 22px;
    }

    .mosl_pdf .stcover13 {
        height: auto;
    }

    .mosl_pdf .stfooter_new2 img {
        height: 22px;
    }

    .mosl_pdf .stfooter_new4 {
        display: inline-block;
        padding: 0px 2px 0px 0px;
    }

    .mosl_pdf .stfooter_new5 img {
        height: 22px;
    }

    .mosl_pdf .coverbg {
        border-left: 8px solid #fbb12f;
        border-right: 8px solid #fbb12f;
        border-bottom: 8px solid #fbb12f;
    }

    /* ***********axis********** */
    .axis_pdf .stcover2 img {
        width: 100%;
        height: auto;
    }

    .axis_pdf .mojoreportlogo {
        display: inline-block;
        padding-right: 0px;
        top: 10px;
        position: relative;
        right: 0px;
    }

    .axis_pdf .mojoreportlogo2 {
        display: inline-block;
        padding-right: 15px;
        float: right;
        top: 8px;
    }

    .axis_pdf .mojoreportlogo2 img {
        height: 22px;
        text-align: right;
        float: right;
    }

    .axis_pdf .mojoreportlogo img {
        height: 22px;
    }

    .axis_pdf .stcover13 {
        height: auto;
    }

    .axis_pdf .stfooter_new2 img {
        height: 22px;
    }

    .axis_pdf .stfooter_new4 {
        display: inline-block;
        padding: 0px 2px 0px 0px;
    }

    .axis_pdf .stfooter_new5 img {
        height: 22px;
    }

    .axis_pdf .coverbg {
        border-left: 8px solid #96144c;
        border-right: 8px solid #96144c;
        border-bottom: 8px solid #96144c;
    }

    /* ***********axis********** */
    .aditya_pdf .stcover2 img {
        width: 100%;
        height: auto;
    }

    .aditya_pdf .mojoreportlogo {
        display: inline-block;
        padding-right: 0px;
        top: 10px;
        position: relative;
        right: 0px;
    }

    .aditya_pdf .mojoreportlogo2 {
        display: inline-block;
        padding-right: 15px;
        float: right;
        top: 8px;
    }

    .aditya_pdf .mojoreportlogo2 img {
        height: 22px;
        text-align: right;
        float: right;
    }

    .aditya_pdf .mojoreportlogo img {
        height: 22px;
    }

    .aditya_pdf .stcover13 {
        height: auto;
    }

    .aditya_pdf .stfooter_new2 img {
        height: 22px;
    }

    .aditya_pdf .stfooter_new4 {
        display: inline-block;
        padding: 0px 2px 0px 0px;
    }

    .aditya_pdf .stfooter_new5 img {
        height: 22px;
    }

    .aditya_pdf .coverbg {
        border-left: 8px solid #8c9c71;
        border-right: 8px solid #8c9c71;
        border-bottom: 8px solid #8c9c71;
    }

    #performanceanalysis {
        margin-bottom: 42px;
    }

    .mt10 {
        width: 38%;
    }
}

.disclimertext {
    position: absolute;
    bottom: 80px;
    width: 955px;
}

@media only screen and (min-width:1024px) {
    .no-web {
        display: none !important;
    }
}

@media only screen and (min-width:960px) and (max-width:1024px) {
    .no-tab {
        display: none !important;
    }
}

@media only screen and (min-width:750px) and (max-width:960px) {
    .no-tab3 {
        display: none !important;
    }

    .no-tab2 {
        display: none !important;
    }
}

@media only screen and (max-width:750px) {
    .no-mob {
        display: none !important;
    }
}

.disclimertextnew {
    width: 100%;
    float: left;
    margin: auto;
    padding: 0px 0px 6px;
    font-size: 12px;
    line-height: 16px;
}

.stfooter_new5 img {
    height: 16px;
}

.smttext {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
}